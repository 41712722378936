<template>
  <v-container class="my-6 mx-auto" fluid>
    <v-row>
      <v-col cols="12">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">
          Сорилууд <span v-if="assignments">({{ assignments.length }})</span>
        </h5>
        <p class="text-typo">Нээлттэй сорилууд</p>
      </v-col>
    </v-row>
    <section v-if="assignments && assignments.length > 0">
      <v-row>
        <v-col
          lg="4"
          md="6"
          cols="12"
          v-for="assignment in assignments"
          :key="assignment.id"
        >
          <v-card
            :style="assignment.cityCode ? 'background-color: #f7f7e2' : ''"
            class="card-shadow border-radius-xl"
            :ripple="false"
            @click="_print(assignment)"
          >
            <div class="px-4 py-4">
              <v-row justify="space-between">
                <v-col sm="12" md="6" lg="6">
                  <h6 class="text-h6 text-typo font-weight-bold">
                    {{ assignment.name }}
                    <small
                      v-if="assignment.assignmentType == 2"
                      style="color: red"
                      >ЭЕШ-шалгалт</small
                    >
                  </h6>
                </v-col>
                <v-col
                  sm="12"
                  md="6"
                  lg="6"
                  class="text-end"
                  v-if="
                    assignment.cityCode &&
                    assignment.cityCodeName &&
                    assignment.cityCode == userData.school.cityCode
                  "
                >
                  <v-btn elevation="0" x-small color="green" dark>{{
                    assignment.cityCodeName
                  }}</v-btn>
                </v-col>
              </v-row>
              <v-row justify="start" class="mt-0">
                <v-col>
                  <small class="text-h6">
                    {{ assignment.duration }} мин,
                    {{ assignment.finalScore }}
                    оноо</small
                  >
                </v-col>
                <v-col
                  class="text-end"
                  v-if="assignment.results && assignment.results.examTaken"
                >
                  <h1
                    class="mr-1 green--text"
                    v-if="assignment.results.totalScore"
                  >
                    {{ assignment.results.totalScore }} оноо
                  </h1>
                </v-col>
              </v-row>
              <hr class="horizontal dark my-4" />
              <small v-if="assignment.description">{{assignment.description}}</small>
              <v-card-actions class="d-flex flex-row justify-end pb-4">
                <!-- <h3 class="blue--text" v-if="assignment.createdYear">
                  {{ assignment.createdYear }}.{{ assignment.createdMonth }}
                </h3> -->
                <v-spacer></v-spacer>
                <v-btn
                  color="green"
                  v-if="assignment.results && assignment.results.examTaken"
                  text
                  @click="_showResult(assignment, false)"
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-success bg-gradient-success"
                  elevation="0"
                  :ripple="false"
                  >Хариулт харах</v-btn
                >
                <v-btn
                  v-else
                  @click="showWarningAlert(assignment)"
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-warning bg-gradient-warning"
                  elevation="0"
                  :ripple="false"
                  >Сорил өгөx</v-btn
                >
              </v-card-actions>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </section>
    <section v-else>
      <v-progress-linear
        class="mb-4"
        v-if="loading"
        indeterminate
        color="red"
      ></v-progress-linear>
    </section>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);

export default {
  components: {},
  data: () => ({
    loading: false,
    assignments: null,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },
  },
  created() {
    this._setupp();
  },
  methods: {
    _print(assignment) {
      console.log(assignment.ref.path);
    },
    _showResult(assignment) {
      console.log(assignment.results.ref.path);
      this.$router.push({
        name: "StudentShowAssignmentResults",
        params: {
          sorilId: assignment.results.ref.path,
          fromEYESH: fb.EYESH_APP ? false : true,
        },
      });
    },
    showWarningAlert(assignment) {
      this.$swal({
        title: "Сорилд оролцоxод бэлэн үү?",
        text:
          assignment.name +
          ", " +
          assignment.finalScore +
          " оноотой!" +
          " <Тийм>  гэсэн товч дарсанаар сорил эxлэнэ!" +
          " Xугацаа: " +
          assignment.duration +
          " мин",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this._takeExam(assignment);
        }
      });
    },
    _takeExam(assignment) {
      this.$router.push({
        name: "XShalgaltEyeshSharing",
        params: {
          shalgaltIdd: assignment.ref.path,
          noRegister: true,
          fromEYESH: fb.EYESH_APP ? false : true,
          backRouterPath: this.$route.path,
        },
      });
    },
    async _setupp() {
      var query = this.userData.ref.collection(
        "lessons-interested-" + this.userData.school.currentYear
      );
      query.onSnapshot(async (docs) => {
        this.assignments = [];
        docs.forEach(async (doc) => {
          //for allschool open assignments
          this.loading = true;
          fb.dbeyesh800
            .collection("_public-assignments-allschools")
            .where(
              "categoryRefPath",
              "==",
              doc.data().lessonCategoryRef.path
                ? doc.data().lessonCategoryRef.path
                : doc.data().lessonCategoryRef
            )
            .orderBy("createdAt", "desc")
            .onSnapshot((docs) => {
              this.assignments = [];
              docs.forEach(async (doc) => {
                let assign = doc.data();
                assign.ref = doc.ref;
                assign.id = doc.id;
                let originalAssignment = null;
                if (fb.EYESH_APP) {
                  fb.db
                    .doc(assign._originalAssignmentRefPath)
                    .onSnapshot(async (doc) => {
                      originalAssignment = doc.data();
                      originalAssignment.id = doc.id;
                      originalAssignment.ref = doc.ref;
                      await originalAssignment.ref
                        .collection("results")
                        .doc(this.userData.id)
                        .get()
                        .then((doc) => {
                          if (doc.exists) {
                            let results = doc.data();
                            results.ref = doc.ref;
                            results.id = doc.id;
                            results.examTaken = true;
                            originalAssignment.results = results;
                          } else {
                            let results = {};
                            results.examTaken = false;
                            originalAssignment.results = results;
                          }
                        });
                      if (originalAssignment.deleted == false)
                        this.assignments.push(originalAssignment);
                    });
                } else {
                  fb.dbeyesh800.doc(assign.ref.path).onSnapshot(async (doc) => {
                    originalAssignment = doc.data();
                    originalAssignment.id = doc.id;
                    originalAssignment.ref = doc.ref;
                    originalAssignment.schoolStatus = null;
                    await originalAssignment.ref
                      .collection("schools")
                      .doc(this.userData.school.id)
                      .get()
                      .then((doc) => {
                        if (doc.exists) {
                          originalAssignment.schoolStatus = doc.data();
                          originalAssignment.schoolStatus.id = doc.id;
                          originalAssignment.schoolStatus.ref = doc.ref;
                        }
                      });
                    await originalAssignment.ref
                      .collection("results")
                      .doc(this.userData.id)
                      .get()
                      .then((doc) => {
                        if (doc.exists) {
                          let results = doc.data();
                          results.ref = doc.ref;
                          results.id = doc.id;
                          results.examTaken = true;
                          console.log(results.ref.path);
                          originalAssignment.results = results;
                        } else {
                          let results = {};
                          results.examTaken = false;
                          originalAssignment.results = results;
                        }
                      });

                    if (
                      (originalAssignment.deleted == false &&
                        originalAssignment.schoolStatus &&
                        originalAssignment.schoolStatus.open) ||
                      originalAssignment.openForAllSchool
                    ) {
                      var found = this.assignments.find(
                        (assign) => assign.id == originalAssignment.id
                      );
                      if (!found) this.assignments.push(originalAssignment);
                    }
                  });
                }
              });
              this.loading = false;
            });
        });
      });
    },
  },
};
</script>
